import React from "react";
import {SpinnerButton} from "./SpinnerButton";
import {Typography} from "@mui/material";
import "./WhiteButton.scss";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";


export function WhiteButton({children, ...props}) {
    return <SpinnerButton className={"btn btn-primary white-button"}
                          variant={"contained"}
                          {...props}
    >
        <Typography variant={"span"}><LocalTranslatedText language={"en"} text={children} /></Typography>
    </SpinnerButton>
}

