import axios from "axios";
import {ensureMinimumPromiseResolveTime} from "../../../core/frontend/utils/time";
import {axiosETAGCache} from "axios-etag-cache";

let globalDocumentTemplateSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getDocumentTemplateSchema() {
        if (globalDocumentTemplateSchema) {
            return globalDocumentTemplateSchema;
        }
        const response = await axiosWithETAGCache.get(`/document_template/schema`);
        globalDocumentTemplateSchema = response.data;
        return response.data;
    },
    async createNewDocumentTemplate(newDocumentTemplate) {
        const body = newDocumentTemplate;

        const response = await ensureMinimumPromiseResolveTime(axios.post(`/document_template`, body), 1000);

        return response.data;
    },
    async getDocumentTemplateTable(tableQuery) {
        const query = {
            query: JSON.stringify(tableQuery)
        };

        const response = await axiosWithETAGCache.get(`/document_template_table`, {params: query});

        return response.data;
    },
    async getDocumentTemplate(documentTemplateId) {
        const body = {}

        const response = await axiosWithETAGCache.get(`/document_template/${documentTemplateId}`, body);

        return response.data;
    },
    async saveDocumentTemplate(templateId, changes) {
        const response = await axios.put(`/document_template/${templateId}`, changes);

        return response.data;
    },
    async deleteDocumentTemplate(documentTemplateId) {
        const response = await ensureMinimumPromiseResolveTime(axios.delete(`/document_template/${documentTemplateId}`), 1000);

        return response.data;
    },
}

export default api;