import React, {useCallback, useState} from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ListItemIcon from "@mui/material/ListItemIcon";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {CardHeader} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import "./OnboardingChecklist.scss";
import {useNavigate} from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";


export default function OnboardingChecklist({checklistData, defaultIsExpanded}) {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(defaultIsExpanded ?? true);

    const handleClick = useCallback((checklistItem) => {
        const url = checklistItem.resolve_url;
        if (url) {
            if (url.startsWith("/")) {
                navigate(url);
            } else {
                window.location.href = url;
            }
        }
    }, [navigate]);

    const handleExpandClick = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded]);

    if (!checklistData) {
        return null;
    }

    return (
        <div className={"onboarding-checklist"}>
            <Card variant="outlined">
                <CardHeader title={
                    <span className={"onboarding-checklist-header"}>
                        {
                            !checklistData.all_completed ?
                                <WarningIcon color={"warning"} size={"xl"}/>
                                : null
                        }
                        <LocalTranslatedText language={"en"} text="Getting Started"/>
                    </span>
                } action={
                    <IconButton aria-label="settings">
                        {
                            isExpanded ?
                                <ExpandLessIcon/>
                                : <ExpandMoreIcon/>
                        }
                    </IconButton>
                } onClick={handleExpandClick}/>
                {isExpanded ?
                    <CardContent>
                        <List
                            sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            {
                                checklistData.items.length === 0 ?
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <CheckBoxIcon
                                                sx={{fontSize: 32}}
                                                color={"success"}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={"No tasks required for onboarding"}
                                            secondary={<LocalTranslatedText
                                                language={"en"}
                                                text="You have completed all the tasks required for onboarding."
                                            />}
                                        />
                                    </ListItemButton>
                                    : null
                            }
                            {
                                checklistData.items.map((item) => {
                                    if (item.is_completed) {
                                        return <ListItemButton onClick={() => handleClick(item)}>
                                            <ListItemIcon>
                                                <CheckBoxIcon
                                                    sx={{fontSize: 32}}
                                                    color={"success"}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<LocalTranslatedText language={"en"} text={item.title} />}
                                                secondary={<>
                                                    <LocalTranslatedText language={"en"} text={item.description} />
                                                    <br/>
                                                    <LocalTranslatedText language={"en"} text={item.reason} />
                                                </>}
                                            />
                                        </ListItemButton>
                                    } else {
                                        return <ListItemButton onClick={() => handleClick(item)}>
                                            <ListItemIcon>
                                                <CheckBoxOutlineBlankIcon
                                                    sx={{fontSize: 32}}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<LocalTranslatedText language={"en"} text={item.title} />}
                                                secondary={<div className={"checklist-item-description"}>
                                                    <LocalTranslatedText language={"en"} text={item.description} />
                                                    <br/>
                                                    <WarningIcon color={"warning"} fontSize="small"/>
                                                    <LocalTranslatedText language={"en"} text={item.reason} />
                                                </div>}
                                            />
                                        </ListItemButton>
                                    }
                                })
                            }
                        </List>
                    </CardContent>
                    : null}
            </Card>
        </div>
    );
}