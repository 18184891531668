import * as React from 'react';
import {TableView} from "../../frontend/elements/TableView";
import {useAPI} from "../../frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function DocumentTemplateTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleDocumentTemplateRowClicked = useCallback((documentTemplate) => {
        navigate(`/document_template/${documentTemplate._id}`);
    }, [navigate]);

    const handleDeleteClicked = useCallback((documentTemplate) => {
        return api.deleteDocumentTemplate(documentTemplate._id);
    }, [api]);

    const handleCopyClicked = useCallback((documentTemplate) => {
        // First lets fetch the full document template object with all of its data
        return api.getDocumentTemplate(documentTemplate._id).then((documentTemplate) => {
            const newDocumentTemplate = {
                _id: null,
                title: documentTemplate.title + " (Copy)",
                ...documentTemplate,
            };

            return api.createNewDocumentTemplate(newDocumentTemplate);
        });
    }, [api]);

    return (
        <TableView
            schemaAPI={api.getDocumentTemplateSchema}
            fetchAPI={api.getDocumentTemplateTable}
            onRowClicked={handleDocumentTemplateRowClicked}
            onDeleteClicked={handleDeleteClicked}
            onCopyClicked={handleCopyClicked}
        />
    );
}
