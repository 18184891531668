import axios from "axios";
import { createContext } from "react";
import { axiosETAGCache } from "axios-etag-cache";
import billingApi from "../../../core/billing/frontend/apis";
import bulkChartEvaluationApi from "../../../core/bulk_chart_evaluations/frontend/apis";
import businessInfoApi from "../../../shared/business_info/frontend/apis";
import constructionResumeBuilderApi from "../../../construction_resume_builder/frontend/apis";
import conversationsApi from "../../../core/conversations/frontend/apis";
import customersApi from "../../../remindertap/customers/frontend/apis";
import outboundsApi from "../../../core/outbound/frontend/apis";
import knowledgeBaseApi from "../../../core/knowledge_base/frontend/apis";
import mybookrobotApi from "../../../mybookrobot/frontend/apis";
import fileUploadAPI from "../../../core/file_uploads/frontend/apis";
import insuranceAgentsApi from "../../../remindertap/insurance_agents/frontend/apis";
import networkingBotAPI from "../../../networkingbot/frontend/apis";
import onboardingApi from "../../../core/onboarding/frontend/apis";
import orderContentGeneratorApi from "../../../order_content_generator/frontend/apis";
import prelimApi from "../../../prelim/frontend/apis";
import promptChartApi from "../../../core/prompt_chart/frontend/apis";
import salespersonAPI from "../../../salesperson/frontend/apis";
import schedulingApi from "../../../shared/scheduling/frontend/apis";
import syntheticUserApi from "../../../core/synthetic/frontend/apis";
import trainingApi from "../../../core/training/frontend/apis";
import translationApi from "../../../core/translation/frontend/apis";
import voiceApi from "../../../core/voice/frontend/apis";
import webApi from "../../../core/web/frontend/apis";
import wordFactoryApi from "../../../wordfactory/frontend/apis";
import imageUploadApi from "../../../core/image_uploads/frontend/apis";
import config from "../../../core/frontend/components/config";
import documentGeneratorApi from "../../../core/document_generator/frontend/apis";

export const apiAccessTokenContext = createContext(null);

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);

export const api = {
  async home() {
    const response = await axiosWithETAGCache.get(`/`);
    return response.data;
  },
  async features() {
    const response = await axiosWithETAGCache.get(`/features`);
    return response.data;
  },
  ...billingApi,
  ...bulkChartEvaluationApi,
  ...businessInfoApi,
  ...constructionResumeBuilderApi,
  ...conversationsApi,
  ...customersApi,
  ...outboundsApi,
  ...knowledgeBaseApi,
  ...mybookrobotApi,
  ...networkingBotAPI,
  ...fileUploadAPI,
  ...insuranceAgentsApi,
  ...onboardingApi,
  ...orderContentGeneratorApi,
  ...prelimApi,
  ...promptChartApi,
  ...salespersonAPI,
  ...schedulingApi,
  ...syntheticUserApi,
  ...trainingApi,
  ...translationApi,
  ...voiceApi,
  ...webApi,
  ...wordFactoryApi,
  ...imageUploadApi,
  ...documentGeneratorApi,
};

axios.defaults.baseURL = config.REACT_APP_BACKEND_API_URL;
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // Auth0.logout();
      return Promise.reject(error);
    }

    if (config.REACT_APP_DEBUG === "true") {
      alert(error.toString());
    } else {
      // Force reload the page. maybe this will help.
      // window.location.reload();
    }
    // Do something with response error
    return Promise.reject(error);
  }
);
