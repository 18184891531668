import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import ErrorBoundary from "../components/ErrorBoundary";
import React, {useEffect} from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {SnackbarProvider} from "../components/SnackbarProvider";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {ThemeProvider} from "@mui/material";
import {theme} from "../theme/theme";
import {APIProvider} from "../components/APIProvider";
import {PageHeader} from "../elements/PageHeader";
import {PageWrapperWithMenu} from "../elements/PageWrapperWithMenu";
import {FeaturesProvider} from "../components/FeaturesProvider";
import {WaitForBackendAwakeWrapper} from "../components/WaitForBackendAwakeWrapper";
import config from "../../../core/frontend/components/config";
import {BackendPingWrapper} from "../components/BackendPingWrapper";
import OnboardingPopupWrapper from "../../onboarding/frontend/OnboardingPopupWrapper";
import "../components/ErrorReporting";
import {GoogleMapsProvider} from "../components/GoogleMapsProvider";
import DocumentTemplateListPage from "../../document_generator/frontend/DocumentTemplateListPage";

const LoadableWithoutOnboarding = ({loader, loading}) => {
    const LoadableComponent = React.lazy(loader);
    return () => (
        <React.Suspense fallback={<PageWrapperWithMenu/>}>
            <LoadableComponent/>
        </React.Suspense>
    );
};

const Loadable = ({loader, loading}) => {
    const LoadableComponent = React.lazy(loader);
    return () => (
        <React.Suspense fallback={<PageWrapperWithMenu/>}>
            <OnboardingPopupWrapper>
                <LoadableComponent/>
            </OnboardingPopupWrapper>
        </React.Suspense>
    );
};

const LoadableHomePage = LoadableWithoutOnboarding({
    loader: () => import("../pages/HomePage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableConversationsListPage = Loadable({
    loader: () => import("../../conversations/frontend/ConversationsListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableConversationViewPage = Loadable({
    loader: () => import("../../conversations/frontend/ConversationViewPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableCustomersListPage = Loadable({
    loader: () =>
        import("../../../remindertap/customers/frontend/CustomersListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableCustomerEditPage = Loadable({
    loader: () =>
        import("../../../remindertap/customers/frontend/CustomerEditPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableBusinessInfoPage = Loadable({
    loader: () =>
        import("../../../shared/business_info/frontend/BusinessInfoPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableSchedulingRulesPage = Loadable({
    loader: () =>
        import(
            "../../../shared/scheduling/frontend/scheduling_rules/SchedulingRulesPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableTrainingPage = Loadable({
    loader: () => import("../../training/frontend/TrainingPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableConversationLabellingPage = Loadable({
    loader: () => import("../../training/frontend/ConversationLabellingPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableChatWithBotPage = Loadable({
    loader: () => import("../../web/frontend/ChatWithBotPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableVoiceConfigurationPage = Loadable({
    loader: () => import("../../voice/frontend/VoiceConfigurationPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableBookingsListPage = Loadable({
    loader: () =>
        import("../../../shared/scheduling/frontend/bookings/BookingsListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableNewBookingPage = Loadable({
    loader: () =>
        import("../../../shared/scheduling/frontend/bookings/NewBookingPage"),
    loading: () => (
        <PageWrapperWithMenu>
            <PageHeader title={`New Booking`}/>
        </PageWrapperWithMenu>
    ),
});

const LoadableEditBookingPage = Loadable({
    loader: () =>
        import("../../../shared/scheduling/frontend/bookings/EditBookingPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableRunSyntheticUserPage = Loadable({
    loader: () => import("../../synthetic/frontend/RunSyntheticUserPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableEditStoryPromptConfigurationPage = Loadable({
    loader: () =>
        import("../../../mybookrobot/frontend/EditStoryPromptConfigurationPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableGenerateNewStoryPage = Loadable({
    loader: () => import("../../../mybookrobot/frontend/GenerateNewStoryPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableStoryListPage = Loadable({
    loader: () => import("../../../mybookrobot/frontend/StoryListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableViewStoryPage = Loadable({
    loader: () => import("../../../mybookrobot/frontend/ViewStoryPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableCalendarListPage = Loadable({
    loader: () =>
        import("../../../shared/scheduling/frontend/calendars/CalendarListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableNewCalendarPage = Loadable({
    loader: () =>
        import("../../../shared/scheduling/frontend/calendars/NewCalendarPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableEditCalendarPage = Loadable({
    loader: () =>
        import("../../../shared/scheduling/frontend/calendars/EditCalendarPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableWebChatConfigurationPage = Loadable({
    loader: () => import("../../web/frontend/WebChatConfigurationPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadablePrivacyPolicyPage = Loadable({
    loader: () => import("../pages/PrivacyPolicyPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableTermsAndConditionsPage = Loadable({
    loader: () => import("../pages/TermsAndConditionsPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableChartListPage = Loadable({
    loader: () => import("../../prompt_chart/frontend/ChartListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableChartEditPage = Loadable({
    loader: () => import("../../prompt_chart/frontend/ChartEditPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableInsuranceAgentsListPage = Loadable({
    loader: () =>
        import(
            "../../../remindertap/insurance_agents/frontend/InsuranceAgentsListPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableNewInsuranceAgentPage = Loadable({
    loader: () =>
        import(
            "../../../remindertap/insurance_agents/frontend/NewInsuranceAgentPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableInsuranceAgentEditPage = Loadable({
    loader: () =>
        import(
            "../../../remindertap/insurance_agents/frontend/InsuranceAgentEditPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableSubscriptionPage = Loadable({
    loader: () => import("../../billing/frontend/SubscriptionPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableContactsListPage = Loadable({
    loader: () => import("../../../networkingbot/frontend/ContactListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableContactEditPage = Loadable({
    loader: () => import("../../../networkingbot/frontend/ContactEditPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadablePersonalInfoPage = Loadable({
    loader: () => import("../../../networkingbot/frontend/PersonalInfoPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableInspectionConfigurationPage = Loadable({
    loader: () =>
        import("../../../prelim/frontend/pages/InspectionConfigurationEditorPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableOrderContentGeneratorPage = Loadable({
    loader: () =>
        import(
            "../../../order_content_generator/frontend/OrderContentGeneratorPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableContentGeneratorOrderListPage = Loadable({
    loader: () =>
        import(
            "../../../order_content_generator/frontend/ContentGeneratorOrderListPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableContentGeneratorOrderViewPage = Loadable({
    loader: () =>
        import(
            "../../../order_content_generator/frontend/ContentGeneratorOrderViewPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableNewCustomerPage = Loadable({
    loader: () =>
        import("../../../remindertap/customers/frontend/NewCustomerPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableSingleChartEvaluationPage = Loadable({
    loader: () =>
        import("../../bulk_chart_evaluations/frontend/SingleChartEvaluationPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableResumeListPage = Loadable({
    loader: () =>
        import(
            "../../../construction_resume_builder/frontend/resume_list/ResumeListPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableResumeEditorPage = Loadable({
    loader: () =>
        import(
            "../../../construction_resume_builder/frontend/resume_editor/ResumeEditorPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableContentSampleListPage = Loadable({
    loader: () =>
        import(
            "../../../wordfactory/frontend/content_sample/ContentSampleListPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableContentSampleEditPage = Loadable({
    loader: () =>
        import(
            "../../../wordfactory/frontend/content_sample/ContentSampleEditPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableNewContentSamplePage = Loadable({
    loader: () =>
        import("../../../wordfactory/frontend/content_sample/NewContentSamplePage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableTopicExtractorPromptListPage = Loadable({
    loader: () =>
        import(
            "../../../wordfactory/frontend/topic_extractor_prompt/TopicExtractorPromptListPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableTopicExtractorPromptEditPage = Loadable({
    loader: () =>
        import(
            "../../../wordfactory/frontend/topic_extractor_prompt/TopicExtractorPromptEditPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableNewTopicExtractorPromptPage = Loadable({
    loader: () =>
        import(
            "../../../wordfactory/frontend/topic_extractor_prompt/NewTopicExtractorPromptPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableTranscriptProcessorConfigurationListPage = Loadable({
    loader: () =>
        import(
            "../../../wordfactory/frontend/transcript_processor_configuration/TranscriptProcessorConfigurationListPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableTranscriptProcessorConfigurationEditPage = Loadable({
    loader: () =>
        import(
            "../../../wordfactory/frontend/transcript_processor_configuration/TranscriptProcessorConfigurationEditPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableNewTranscriptProcessorConfigurationPage = Loadable({
    loader: () =>
        import(
            "../../../wordfactory/frontend/transcript_processor_configuration/NewTranscriptProcessorConfigurationPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableProcessTranscriptPage = Loadable({
    loader: () => import("../../../wordfactory/frontend/ProcessTranscriptPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableGeneratedContentListPage = Loadable({
    loader: () =>
        import(
            "../../../wordfactory/frontend/generated_content/GeneratedContentListPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableGeneratedContentViewPage = Loadable({
    loader: () =>
        import(
            "../../../wordfactory/frontend/generated_content/ViewGeneratedContentPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableResumePromptConfigurationPage = Loadable({
    loader: () =>
        import(
            "../../../construction_resume_builder/frontend/EditResumePromptConfigurationPage"
            ),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableProposalListPage = Loadable({
    loader: () => import("../../../salesperson/frontend/ProposalListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableNewProposalPage = Loadable({
    loader: () => import("../../../salesperson/frontend/NewProposalPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableEditProposalPage = Loadable({
    loader: () => import("../../../salesperson/frontend/ProposalEditPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableGenerateProposalPage = Loadable({
    loader: () => import("../../../salesperson/frontend/GenerateProposalFromRequirementsPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableDocumentListPage = Loadable({
    loader: () => import("../../knowledge_base/frontend/document/DocumentListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableDocumentEditPage = Loadable({
    loader: () => import("../../knowledge_base/frontend/document/DocumentEditPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableNewDocumentPage = Loadable({
    loader: () => import("../../knowledge_base/frontend/document/NewDocumentPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableKnowledgeChunkListPage = Loadable({
    loader: () => import("../../knowledge_base/frontend/knowledge_chunk/KnowledgeChunkListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableKnowledgeChunkViewPage = Loadable({
    loader: () => import("../../knowledge_base/frontend/knowledge_chunk/KnowledgeChunkViewPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableKnowledgeBaseConfigurationEditPage = Loadable({
    loader: () => import("../../knowledge_base/frontend/knowledge_base_configuration/EditKnowledgeBaseConfigurationPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableBulkDocumentUploadPage = Loadable({
    loader: () => import("../../knowledge_base/frontend/document/BulkDocumentUploadPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableDefaultOutboundRulesPage = Loadable({
    loader: () => import("../../outbound/frontend/DefaultOutboundRulesPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableDocumentTemplateListPage = Loadable({
    loader: () => import("../../document_generator/frontend/DocumentTemplateListPage"),
    loading: () => <PageWrapperWithMenu/>,
});

const LoadableDocumentTemplateEditPage = Loadable({
    loader: () => import("../../document_generator/frontend/DocumentTemplateEditPage"),
    loading: () => <PageWrapperWithMenu/>,
});




const router = createBrowserRouter(
    [
        {
            path: "",
            element: <LoadableHomePage/>,
        },
        {
            path: "conversations",
            element: <LoadableConversationsListPage/>,
        },
        {
            path: "conversations/:conversationId",
            element: <LoadableConversationViewPage/>,
        },
        {
            path: "business_info",
            element: <LoadableBusinessInfoPage/>,
        },
        {
            path: "scheduling_rules",
            element: <LoadableSchedulingRulesPage/>,
        },
        {
            path: "customers/new",
            element: <LoadableNewCustomerPage/>,
        },
        {
            path: "customers",
            element: <LoadableCustomersListPage/>,
        },
        {
            path: "customers/:customerId",
            element: <LoadableCustomerEditPage/>,
        },
        {
            path: "training",
            element: <LoadableTrainingPage/>,
        },
        {
            path: "labelling/:conversationId",
            element: <LoadableConversationLabellingPage/>,
        },
        {
            path: "customers",
            element: <LoadableCustomersListPage/>,
        },
        {
            path: "customers/:customerId",
            element: <LoadableCustomerEditPage/>,
        },
        {
            path: "chat",
            element: <LoadableChatWithBotPage/>,
        },
        {
            path: "voice_configuration",
            element: <LoadableVoiceConfigurationPage/>,
        },
        {
            path: "bookings/new",
            element: <LoadableNewBookingPage/>,
        },
        {
            path: "bookings/:bookingId",
            element: <LoadableEditBookingPage/>,
        },
        {
            path: "bookings",
            element: <LoadableBookingsListPage/>,
        },
        {
            path: "synthetic_user",
            element: <LoadableRunSyntheticUserPage/>,
        },
        {
            path: "story_prompt_configuration",
            element: <LoadableEditStoryPromptConfigurationPage/>,
        },
        {
            path: "generate_story",
            element: <LoadableGenerateNewStoryPage/>,
        },
        {
            path: "calendars/new",
            element: <LoadableNewCalendarPage/>,
        },
        {
            path: "calendars/:calendarId",
            element: <LoadableEditCalendarPage/>,
        },
        {
            path: "calendars",
            element: <LoadableCalendarListPage/>,
        },
        {
            path: "web_chat_configuration",
            element: <LoadableWebChatConfigurationPage/>,
        },
        {
            path: "privacy_policy",
            element: <LoadablePrivacyPolicyPage/>,
        },
        {
            path: "terms_and_conditions",
            element: <LoadableTermsAndConditionsPage/>,
        },
        {
            path: "charts/:chartId",
            element: <LoadableChartEditPage/>,
        },
        {
            path: "charts/",
            element: <LoadableChartListPage/>,
        },
        {
            path: "insurance_agents/new",
            element: <LoadableNewInsuranceAgentPage/>,
        },
        {
            path: "insurance_agents/",
            element: <LoadableInsuranceAgentsListPage/>,
        },
        {
            path: "insurance_agents/:insuranceAgentId",
            element: <LoadableInsuranceAgentEditPage/>,
        },
        {
            path: "subscription",
            element: <LoadableSubscriptionPage/>,
        },
        {
            path: "contacts",
            element: <LoadableContactsListPage/>,
        },
        {
            path: "contacts/:contactId",
            element: <LoadableContactEditPage/>,
        },
        {
            path: "personal_info",
            element: <LoadablePersonalInfoPage/>,
        },
        {
            path: "inspection_configuration",
            element: <LoadableInspectionConfigurationPage/>,
        },
        {
            path: "order-content-generator",
            element: <LoadableOrderContentGeneratorPage/>,
        },
        {
            path: "content-generator-orders",
            element: <LoadableContentGeneratorOrderListPage/>,
        },
        {
            path: "content-generator-orders/:contentGeneratorOrderId",
            element: <LoadableContentGeneratorOrderViewPage/>,
        },
        {
            path: "story",
            element: <LoadableStoryListPage/>,
        },
        {
            path: "story/:storyId",
            element: <LoadableViewStoryPage/>,
        },
        {
            path: "single_chart_evaluation/:singleChartEvaluationId",
            element: <LoadableSingleChartEvaluationPage/>,
        },
        {
            path: "resumes",
            element: <LoadableResumeListPage/>,
        },
        {
            path: "resume/:resumeId/*",
            element: <LoadableResumeEditorPage/>,
        },
        {
            path: "content_samples",
            element: <LoadableContentSampleListPage/>,
        },
        {
            path: "content_samples/new",
            element: <LoadableNewContentSamplePage/>,
        },
        {
            path: "content_samples/:contentSampleId",
            element: <LoadableContentSampleEditPage/>,
        },
        {
            path: "topic_extractor_prompts",
            element: <LoadableTopicExtractorPromptListPage/>,
        },
        {
            path: "topic_extractor_prompts/new",
            element: <LoadableNewTopicExtractorPromptPage/>,
        },
        {
            path: "topic_extractor_prompts/:topicExtractorPromptId",
            element: <LoadableTopicExtractorPromptEditPage/>,
        },
        {
            path: "transcript_processor_configurations",
            element: <LoadableTranscriptProcessorConfigurationListPage/>,
        },
        {
            path: "transcript_processor_configurations/new",
            element: <LoadableNewTranscriptProcessorConfigurationPage/>,
        },
        {
            path: "transcript_processor_configurations/:transcriptProcessorConfigurationId/*",
            element: <LoadableTranscriptProcessorConfigurationEditPage/>,
        },
        {
            path: "process_transcript",
            element: <LoadableProcessTranscriptPage/>,
        },
        {
            path: "generated_content",
            element: <LoadableGeneratedContentListPage/>,
        },
        {
            path: "generated_content/:generatedContentId",
            element: <LoadableGeneratedContentViewPage/>,
        },
        {
            path: "resume_prompt_configuration",
            element: <LoadableResumePromptConfigurationPage/>,
        },
        {
            path: "proposals",
            element: <LoadableProposalListPage/>,
        },
        {
            path: "proposal/new",
            element: <LoadableNewProposalPage/>,
        },
        {
            path: "proposal/generate",
            element: <LoadableGenerateProposalPage/>,
        },
        {
            path: "proposal/:proposalId",
            element: <LoadableEditProposalPage/>,
        },
        {
            path: "/document/:documentId",
            element: <LoadableDocumentEditPage/>,
        },
        {
            path: "/documents/new",
            element: <LoadableNewDocumentPage/>,
        },
        {
            path: "/documents/upload",
            element: <LoadableBulkDocumentUploadPage/>,
        },
        {
            path: "/documents",
            element: <LoadableDocumentListPage/>,
        },
        {
            path: "/knowledge",
            element: <LoadableKnowledgeChunkListPage/>,
        },
        {
            path: "/knowledge/:knowledgeChunkId",
            element: <LoadableKnowledgeChunkViewPage/>,
        },
        {
            path: "/knowledge_base_configuration",
            element: <LoadableKnowledgeBaseConfigurationEditPage/>,
        },
        {
            path: "/default_outbound_rules",
            element: <LoadableDefaultOutboundRulesPage/>,
        },
        {
            path: "/document_template",
            element: <LoadableDocumentTemplateListPage/>,
        },
        {
            path: "/document_template/:documentTemplateId",
            element: <LoadableDocumentTemplateEditPage/>,
        },
    ],
    {
        basename: config.REACT_APP_ROUTER_BASENAME,
    }
);

function MainFrontendApp() {
    // Check if the user has accessed the root URL, without the basename in the path. If so, we redirect
    // them to the appropriate URL with the basename.
    useEffect(() => {
        if (
            window.location.pathname === "/" &&
            config.REACT_APP_ROUTER_BASENAME !== "/"
        ) {
            window.location.pathname = config.REACT_APP_ROUTER_BASENAME;
        }
    }, []);

    // Decide what to show depending on whether we are authenticated or not
    let app = <RouterProvider router={router}/>;

    // Wrap in provider for enabled features in the app
    app = <FeaturesProvider>{app}</FeaturesProvider>;

    // Wrap in provider to wait for the backend server to be alive and awake
    app = <WaitForBackendAwakeWrapper>{app}</WaitForBackendAwakeWrapper>;

    // Wrap in provider for the API itself
    app = <APIProvider>{app}</APIProvider>;

    // Wrap in provider for the server ping process, to ensure backend server is alive.
    // Notably this is done before api provider to ensure it happens before login,
    // so that the server can be waking up while the user finishes their login process
    app = <BackendPingWrapper>{app}</BackendPingWrapper>;

    // Wrap in Snackbar provider
    app = <SnackbarProvider>{app}</SnackbarProvider>;

    // Wrap in a provider for the Google Maps client API
    app = <GoogleMapsProvider>{app}</GoogleMapsProvider>;

    // Wrap in Theme provider
    app = <ThemeProvider theme={theme}>{app}</ThemeProvider>;

    // Wrap in Localization provider
    app = (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {app}
        </LocalizationProvider>
    );

    // Wrap in DnD provider
    app = <DndProvider backend={HTML5Backend}>{app}</DndProvider>;

    // Wrap in ErrorBoundary
    app = <ErrorBoundary>{app}</ErrorBoundary>;

    // Wrap in the auth0 provider
    app = (
        <Auth0Provider
            domain={config.REACT_APP_AUTH0_DOMAIN}
            clientId={config.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: new URL(
                    config.REACT_APP_ROUTER_BASENAME,
                    config.REACT_APP_FRONTEND_URL
                ).href,
                scope: "read:current_user update:current_user_metadata email profile",
                audience: config.REACT_APP_AUTH0_AUDIENCE,
            }}
            cacheLocation='localstorage'
        >
            {app}
        </Auth0Provider>
    );

    return app;
}

export default MainFrontendApp;
